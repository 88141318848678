<template>
	<div class="page-container">
		<div :class="['header', {'unpass': pass==0}]">
			<page-header @navBack="navBack" :back="true" :pageTitle="pageTitle">
			</page-header>
		</div>

		<div class="page-body">

			<div class="circle-wrap">
				<div class="circle">
					<van-circle
						size="116"
						v-model="currentRate"
						:color="gradientColor"
						:stroke-width="80"
						:rate="rate"
						:speed="100"
						:text="text"
					>
						<div class="circle-inner">
							<h3 class="title">{{score}}分</h3>
							<!-- <span>{{pass==0?'不合格':'合格'}}</span> -->
						</div>
					</van-circle>
				</div>
			</div>

			<div class="content">
				<div class="section-top">
					<div class="left">
						<img :src="require('../../assets/icon-result'+(appno==1?'':appno)+'.png')" alt="avatar">
					</div>
					<div class="right">
						<h4 @click="reTest()">重新{{belongOrganization === '13'?'测试':'考试'}}</h4>
						<!-- <span v-if="pass==1">继续学习，稳固成绩</span>
						<span v-if="pass==0">发挥失常，再考一次</span> -->
						<span>发挥失常，再{{belongOrganization === '13'?'测':'考'}}一次</span>
					</div>
				</div>
			</div>
			<div class="section-content">
				<div class="list-item tips-item">
					<van-button
						v-if="pass==1 && belongOrganization != '38'"
						class="btn"
						type="primary"
						block
						@click="showCertificatePage"
					>去下载合格证明
					</van-button>
					<!-- <span class="name c-666">您已完成科{{subject==1?'一':'四'}}的计时培训，请您联系所报考的驾校进行约考。</span> -->
				</div>
			</div>
			<div class="section-bottom">
				<van-button
					class="btn"
					type="primary"
					block
					@click="testing"
				>完成
				</van-button
				>
			</div>

		</div>

	</div>
</template>

<script>
	import PageHeader from "../../components/page-header.vue";
	// import { getCityCode } from '@/utils/cookie';
	import { getPlanInfo,getstudyTotal } from '../../api/user';
	export default {
		data() {
			return {
				pageTitle: "",
				rate: 80,
				currentRate: 0,
				gradientColor: {
					"0%": "#FFE1C1",
					"100%": "#FF8506",
				},
				unPassGradientColor: {
					"0%": "#fc9f9c",
					"100%": "#ea4741",
				},
				text: '',
				subject: '',
				score: '',
				pass: '',
				belongOrganization: '',
			};
		},
		computed: {
			appno() {
				return this.$store.state.app.appno
			},
			courseid() {
				return this.$store.state.user.courseid
			},
			userid() {
				return this.$store.state.user.userid
			},
		},
		components: {
			PageHeader,
		},
		mounted() {
			this.getPlanInfo();
			const {subject, score, pass} = this.$route.query;
			this.subject = subject;
			this.score = score;
			this.pass = pass;

			const appno = sessionStorage.getItem('appno');
			switch (appno) {
				case 'APP02':
					this.gradientColor = {
						"0%": "#96cdfa",
						"100%": "#2db1ac",
					}
					this.isSupportSubject = false;
					break;
				case 'APP03':
					this.gradientColor = {
						"0%": "#b1ffee",
						"100%": "#1175fe",
					}
					this.isSupportSubject = false;
					break;
				case 'APP04':
					this.colorMain = '#ff8506';
					this.gradientColor = {
						"0%": "#FFE1C1",
						"100%": "#FF8506",
					}
					this.isSupportSubject = false;
					break;
				case 'APP05':
					this.colorMain = '#ff8506';
					this.gradientColor = {
						"0%": "#FFE1C1",
						"100%": "#FF8506",
					}
					this.isSupportSubject = false;
					break;
				case 'APP06':
					this.colorMain = '#ff8506';
					this.gradientColor = {
						"0%": "#FFE1C1",
						"100%": "#FF8506",
					}
					this.isSupportSubject = false;
					break;
				case 'APP08':
					this.colorMain = '#ff8506';
					this.gradientColor = {
						"0%": "#FFE1C1",
						"100%": "#FF8506",
					}
					this.isSupportSubject = false;
					break;
				default:
					this.gradientColor = {
						"0%": "#FFE1C1",
						"100%": "#FF8506",
					}
					this.isSupportSubject = true;
					break;
			}
		},
		methods: {
			// 获取学习计划
			getPlanInfo() {
				getPlanInfo(this.userid).then(res => {
					// console.log('res',res);
					this.belongOrganization = res.belongOrganization;
					// this.belongOrganization = '13';
					if (this.belongOrganization === '13') {
						this.pageTitle= "测试成绩"
					} else {
						this.pageTitle= "考试成绩"
					}
				})
			},
			testing() {
				if (this.belongOrganization === '13') {
					// 成绩汇总
					getstudyTotal(this.userid).then(res => {
						console.log(res);
						this.navBack();
					}).catch(() =>{})
				}else{
					this.$router.push({ path: '/train'})
				}
			},
			navBack() {
				this.$router.push({ path: '/train'})
			},
			reTest() {
				this.$router.push({path: '/mock', query: {subject: this.subject}})
			},
			showCertificatePage() {
				if (this.belongOrganization === '13') {
					// 成绩汇总
					getstudyTotal(this.userid).then(res => {
						console.log(res);
						this.navBack();
					}).catch(() =>{})
				}
				const appno = sessionStorage.getItem('appno');
				// const cityCode = getCityCode();
				this.$router.push({
						path: '/studyCertificateList/' + this.userid,
						query: { appNo: appno }
					});
				// if(cityCode === '140700' || cityCode === '150300' || cityCode === '152900') {
				// 	// 晋中各区县/乌海/阿拉善显示合格证列表

				// } else {
				// 	this.$router.push({
				// 		path: '/studyCertificate/' + this.userid,
				// 		query: {appNo: appno}
				// 	});
				// }
			},
		},
	};
</script>

<style lang="less" scoped>

	.header {
		position: absolute;
		width: 100%;
		height: 320px;
		padding-bottom: 14px;
		border-bottom-left-radius: 26px 10px;
		border-bottom-right-radius: 26px 10px;

		// &.unpass {
		// 	background: #ea4741;

		// 	.page-header {
		// 		background: #ea4741 !important;
		// 	}
		// }
	}

	.tips {
		padding: 4px 12px;
		font-size: 12px;
	}


	.page-container {
		min-height: 100vh;
		background: #ffffff;
	}

	.page-body {
		margin-top: 0;
		padding: 60px 15px 15px;

		.content {
			position: relative;
			top: 0;
			background: #fff;
			border-radius: 14px;
			padding: 15px;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);

			.section-top {
				display: flex;

				.left {
					flex: 0 0 76px;
					margin-right: 20px;

					img {
						width: 100%;
					}
				}

				.right {
					display: flex;
					flex-direction: column;
					justify-content: center;

					h4 {
						font-size: 16px;
						color: #444;
						margin: 0 0 4px 0;
					}

					span {
						color: #666;
						font-size: 13px;
					}
				}
			}
		}

		.section-content {
			padding: 20px 10px;
			font-size: 15px;

			.list-item {
				line-height: 1.6;
				margin-bottom: 4px;

				&.tips-item {
					font-size: 13px;
				}
			}
		}

	}

	// .van-popup {
	//   background: transparent;
	// }
	.van-popup .van-popover__arrow {
		background: #fca142;
	}

	.van-popover__content {
		background: #fca142;
		color: #fff;
	}

	.van-circle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.circle-wrap {
		position: relative;
		height: 200px;

		.circle {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 160px;
			height: 160px;
			margin: -80px 0 0 -80px;
			background-image: url(~@/assets/icon-circle-sm.png);
			background-size: cover;

			.circle-inner {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;

				.title {
					margin: 0 0 4px 0;
					color: #ff8506;
					font-size: 20px;
				}

				span {
					color: #999;
					font-size: 11px;
				}
			}
		}
	}

	.popup-content {
		width: 290px;
		// border-radius: 12px;
		overflow: hidden;
		background: #fff;

		& > .title {
			background: linear-gradient(90deg, #FCA142, #FF8506);
			padding: 15px;
			font-size: 18px;
			color: #fff;
			text-align: center;
		}

		.content {
			margin: 20px 0 10px;
			padding: 0 10px;
			font-size: 12px;

			.list {
				display: flex;
				align-items: center;
				margin-top: 10px;

				span {
					display: inline-block;
					width: 50%;
				}
			}
		}

		.btn-wrap {
			display: flex;
			padding: 15px;

			.btn {
				width: 40%;
				font-weight: 500;
			}
		}
	}
</style>
